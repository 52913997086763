<div class="table-area">
    <table class="table">
        <caption>
            SolarView Users
        </caption>
        <thead>
            <tr>
                <th>Email</th>
                <th>Name</th>
                <th>Company</th>
                <th>Heard About SolarView</th>
                <th>Most Interesting</th>
		<th>Platform</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let u of users" [class.selected]="u == selectedUser" (click)="selectedUser = u">
                <td>{{u.email}}</td>
                <td>{{u.name}}</td>
                <td>{{u.company}}</td>
                <td>{{u.hearabout}}</td>
                <td>{{u.mostinteresting}}</td>
		<td>{{u.platform}}</td>
            </tr>
        </tbody>
    </table>
</div>
<br />
<div class="detail-area" *ngIf="selectedUser">
    <center>User Activity</center>
    <clr-tree *ngIf="selectedUser && selectedUser.activity && selectedUser.activity.length > 0">
        <clr-tree-node [clrExpanded]="true">
            Session 1 ({{selectedUser.activity[0].timelog.toLocaleDateString('en-US')}} {{selectedUser.activity[0].timelog.toLocaleTimeString('en-US')}})
            <clr-tree-node *ngFor="let a of selectedUser.activity">
                ({{a.timelog.toLocaleDateString('en-US')}} {{a.timelog.toLocaleTimeString('en-US')}}) Viewed: {{a.page}}
            </clr-tree-node>
        </clr-tree-node>
    </clr-tree>
    <div *ngIf="!selectedUser || !selectedUser.activity || !selectedUser.activity.length">No Data</div>
</div>
