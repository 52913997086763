import { Component, OnInit } from '@angular/core';
import { DataService, IUser } from '../data.service';

import { ChartDataset, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  users: IUser[] = [];



  public lineChartData: ChartDataset[] = [];

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };

  public pieChartOsLabels: any[] = ['iOS', 'Android'];
  public pieChartOsData: any[] = [];


  public pieChartOLabels: any[] = ['United States', 'Mexico', "EU"];
  public pieChartOData: any[] = [];

  constructor(
    private _dataService: DataService
  ) { }

  ngOnInit(): void {
    this._dataService.getUserData().subscribe(u => {
      debugger;
      // if(u.status){

      // }
      // Workarounds for quirks after switching to live data
      u = u.filter(x => !!x.email);
      for (let i = 0; i < u.length; i++) {
        u[i].signupTime = new Date(u[i].signupTime);
        for (let x = 0; x < u[i].activity.length; x++) {
          u[i].activity[x].timelog = new Date(u[i].activity[x].timelog);
        }
      }
      // End workarounds

      console.log(u);

      this.users = u;

      const dateArray: string[] = [];
      let minDate: Date = new Date();

      u.forEach(x => {
        if (!!x.signupTime) {
          if (x.signupTime.getTime() < minDate.getTime()) {
            minDate = x.signupTime;
          }
        }
      });

      const todayDate = new Date().toLocaleDateString('en-US');

      while (minDate.toLocaleDateString('en-US') != todayDate) {
        dateArray.push(minDate.toLocaleDateString('en-US'));
        minDate.setDate(minDate.getDate() + 1);
      }

      console.log(dateArray);

      const sds: any[] = [];

      let acc = 0;

      dateArray.forEach(d => {
        acc += u.filter(uu => uu.signupTime.toLocaleDateString('en-US') == d).length;

        sds.push({
          x: d,
          y: acc
        });
      });

      const ds: ChartDataset = {
        label: "Total Users",
        borderColor: "black",
        pointBorderColor: "black",
        pointBackgroundColor: "black",

        data: sds
      };

      this.lineChartData = [ds];

      const osds: ChartDataset = {
        label: "User Operating System",
        data: [u.filter(x => x.platform == 'ios').length, u.filter(x => x.platform == 'android').length]
      }

      this.pieChartOsData = [osds]; //[u.filter(x => x.platform == 'ios').length, u.filter(x => x.platform == 'android').length];


      const ods: ChartDataset = {
        label: "User Origin",
        data: [u.filter(x => !x.email.endsWith("mx") && !x.email.endsWith("eu")).length,
        u.filter(x => x.email.endsWith("mx")).length,
        u.filter(x => x.email.endsWith("eu")).length]
      }

      this.pieChartOData = [ods]; //[u.filter(x => x.platform == 'ios').length, u.filter(x => x.platform == 'android').length];

      console.log(this.pieChartOsData);
    });
  }
}
