<div class="main-container">
  <!-- <div class="alert alert-app-level alert-info">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="info-circle"></cds-icon>
        </div>
        <div class="alert-text">
          App Level Alert
        </div>
        <div class="alert-actions">
          <button class="btn btn-sm alert-action">Action</button>
        </div>
      </div>
    </div>
    <button type="button" class="close" aria-label="Close">
      <cds-icon aria-hidden="true" shape="close"></cds-icon>
    </button>
  </div> -->
  <header class="header header-4">
    <div class="branding">
      <a href="javascript:void(0)">
        <img style="max-width:100%; max-height:100%; padding: 10px;" src="assets/solarview.png"/>
        <span class="title" style="margin-left: 2ch;">Insights Center</span>
      </a>
    </div>
    <!-- <div class="header-nav">
      <a href="javascript://" class="nav-link nav-icon">
        <cds-icon shape="cloud"></cds-icon>
      </a>
      <a href="javascript://" class="active nav-link nav-icon">
        <cds-icon shape="folder"></cds-icon>
      </a>
    </div> -->
  </header>
  <nav class="subnav">
    <ul class="nav">
      <li class="nav-item">
        <a [routerLink]="'/dashboard'" [routerLinkActive]="['active']" class="nav-link">Dashboard</a>
      </li>
      <li class="nav-item">
        <a [routerLink]="'/users'" [routerLinkActive]="['active']" class="nav-link">User Management</a>
      </li>
    </ul>
  </nav>
  <div class="content-container">
    <router-outlet></router-outlet>
  </div>
</div>