import { Component, OnInit } from '@angular/core';
import { DataService, IUser } from '../data.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  users: IUser[] = [];

  selectedUser: IUser = null;

  constructor(
    private _dataService: DataService
  ) { }

  ngOnInit(): void {
    let platforms = ["MacIntel", "Win32", "Linux x86_64", "Linux armv81"];
    let objArr = [];
    this._dataService.getUserData().subscribe(u => {
      // Workarounds for quirks after switching to live data
      u = u.filter(x => !!x.email);

      for (let i = 0; i < u.length; i++) {
        u[i].signupTime = new Date(u[i].signupTime);
        objArr.splice(0, objArr.length);
        objArr.push(u[i].platform);
        const atLeastOne = objArr.some(i => platforms.includes(i))
        if(atLeastOne)
        {
          u[i].platform = 'Web';
        }
        else{
          u[i].platform = 'Mobile';
        }
        for (let x = 0; x < u[i].activity.length; x++) {
          u[i].activity[x].timelog = new Date(u[i].activity[x].timelog);
        }
      }
      // End workarounds
      this.users = u.sort((a, b) => b.signupTime.getTime() - a.signupTime.getTime());
    });
  }

}

