<div class="content-area">

    <div class="clr-row">
        <div class="clr-col-6">
            <div class="card">
                <div class="card-header">
                    User OS
                </div>
                <div class="card-block">
                    <!-- <div class="card-title">
                        Block
                    </div> -->
                    <div class="card-text">
                        <canvas baseChart 
                        [labels]="pieChartOsLabels" 
                        [datasets]="pieChartOsData" 
                        [options]="pieChartOptions"
                        [type]="'pie'"
                        [legend]="true">
                      </canvas>
                    </div>
                </div>
            </div>
        </div>
        <div class="clr-col-6">
            <div class="card">
                <div class="card-header">
                    User Origin
                </div>
                <div class="card-block">
                    <!-- <div class="card-title">
                        Block
                    </div> -->
                    <div class="card-text">
                        <canvas baseChart 
                        [labels]="pieChartOLabels" 
                        [datasets]="pieChartOData" 
                        [options]="pieChartOptions"
                        [type]="'pie'"
                        [legend]="true">
                      </canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="clr-row">
        <div class="clr-col-12">
            <div class="card">
                <div class="card-header">
                    User Signups
                </div>
                <div class="card-block">
                    <!-- <div class="card-title">
                        Block
                    </div> -->
                    <div class="card-text">
                        <canvas baseChart [datasets]="lineChartData"
                            [legend]="true" [type]="'line'">
                        </canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>














</div>





<!-- <nav class="sidenav">
    <section class="sidenav-content">
        <section class="nav-group">
            <input id="tabexample2" type="checkbox" />
            <label for="tabexample2">Sidenav</label>
            <ul class="nav-list">
                <li><a class="nav-link">Link 1</a></li>
                <li><a class="nav-link">Link 2</a></li>
                <li><a class="nav-link active">Link 3</a></li>
                <li><a class="nav-link">Link 4</a></li>
                <li><a class="nav-link">Link 5</a></li>
                <li><a class="nav-link">Link 6</a></li>
            </ul>
        </section>
    </section>
</nav> -->