import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import * as internal from 'stream';

export interface IActivity {
  id: number;
  timelog: Date;
  page: string;
  email: string;
}

export interface IUser {
  id: number;
  email: string;
  name: string;
  jobtitle: string;
  company: string;
  hearabout: string;
  mostinteresting: string;
  activity: IActivity[];
  signupTime: Date;
  platform: string;
}

// export interface Output {
//   message: string
// response: IUser[]
// status: boolean
// statusCode: number
// }



@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(
    private http: HttpClient
  ) { }

  getUserData(): Observable<IUser[]> {
    // return of(mockData);

    return this.http.get<IUser[]>('https://apis.solarview.app/api/user/get_dashboard_data');  //https://stickup-cms.sentibyte.com/user/get_dashboard_data
  }
}
